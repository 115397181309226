<template>
  <div ref="draggableContainer"  :view="name" :width="wwidth":style="{ top: position.top + 'px', left: position.left + 'px',width: wwidth + 'px' }" :class="{ 'animationOw' : name == 'ow', 'bottom-2 right-4 absolute' : isFixed, 'draggableClass' : !isFixed }">
    <slot name="promt"></slot>
    <div id="draggable-header" @mousedown="startDrag">
      <slot name="header"></slot>
    </div>
    <slot name="main"></slot>
    <slot name="footer"></slot>
  </div>
</template>

<!-- old code  -->
<!-- <script>
import { mapGetters } from 'vuex'
export default {
  name: 'DraggableDiv',
  data: function () {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
  methods: {
    dragMouseDown: function (event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {

      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;

      var PADDING = 0;

      var viewport = {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0
      };

      var rect = this.$refs.draggableContainer.getBoundingClientRect();
      viewport.bottom = window.innerHeight - PADDING;
      viewport.left = PADDING;
      viewport.right = window.innerWidth - PADDING;
      viewport.top = PADDING;
      var newLeft = this.$refs.draggableContainer.offsetLeft - this.positions.movementX;
      var newTop = this.$refs.draggableContainer.offsetTop - this.positions.movementY;

      if (newLeft < viewport.left
        || newTop < viewport.top
        || newLeft + rect.width > viewport.right
        || newTop + rect.height > viewport.bottom
      ) {


        // the element will hit the boundary, do nothing...
      } else {

        // set the element's new position:
        this.$refs.draggableContainer.style.top = this.$refs.draggableContainer.offsetTop -
          this.positions.movementY +
          "px";
        this.$refs.draggableContainer.style.left = this.$refs.draggableContainer.offsetLeft -
          this.positions.movementX +
          "px";

        if (this.isFixed) {
          this.$refs.draggableContainer.style.left = "calc(100vw-100px) !important;";
        } else {
          this.$refs.draggableContainer.style.left = this.$refs.draggableContainer.offsetLeft - this.positions.movementX + "px";
        }

        this.$refs.draggableContainer.style.bottom = 'auto'
      }
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    }
  },
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
    wwidth: { type:String },
    name: { type: String }
  },
  computed: {
    ...mapGetters(['getIsOpenOw'])
  },
}
</script> -->
<script lang="ts">
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'DraggableDiv',
  setup() {
  const isDragging = ref(false)
  const position = ref({
        top: 100,
        left: 100
      })
  const offset = ref({
        x: 0,
        y: 0
      })
  const boundary = ref({
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      })
    return {
      isDragging,position,offset,boundary
    };
  },
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
    wwidth: { type:String },
    name: { type: String }
  },

  mounted() {
    this.setBoundary();
    window.addEventListener('resize', this.setBoundary);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setBoundary);
  },
  methods: {
    setBoundary() {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      this.boundary = {
        top: 0,
        left: 0,
        right: windowWidth,
        bottom: windowHeight
      };
    },
    startDrag(event:any) {
      this.isDragging = true;
      this.offset.x = event.clientX - this.position.left;
      this.offset.y = event.clientY - this.position.top;

      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.stopDrag);
    },
    onMouseMove(event:any) {
      if (this.isDragging) {
        const newLeft = event.clientX - this.offset.x;
        const newTop = event.clientY - this.offset.y;

        // Ensure the draggable element stays within the window boundaries
        this.position.left = Math.max(this.boundary.left, Math.min(newLeft, this.boundary.right - this.$el.offsetWidth));
        this.position.top = Math.max(this.boundary.top, Math.min(newTop, this.boundary.bottom - this.$el.offsetHeight));
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.stopDrag);
    }
  }
});
</script>

<style>
.draggableClass {
  left: 30%;
  top: 25%;
  position: absolute;
  z-index: 999;
  height: auto !important;
  max-width: 634px;
  /* filter: drop-shadow(3px 3px 8px rgba(0.25, 0.25, 0.5, 0.45)) !important; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* box-shadow: 0px 0px 8px 0px #00000040; */
  @apply border border-gray-300 rounded-lg
}

#draggable-header {
  z-index: 10;
  cursor: move !important;
}
.centerShow{
  top: 25vh;
  left: 40%;
}
/* .animationOw {
  animation: modal-slide 0.3s;
} */
/* Animation keyframes for modal slide */
@keyframes modal-slide {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Animation keyframes for modal slide in */
/* @keyframes modal-slide-in {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
} */

/* Animation keyframes for modal slide out */
/* @keyframes modal-slide-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
} */

</style>