<template>
    <div class="relative z-index-full" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="fixed inset-0 bg-black bg-opacity-25 transition-opacity"></div>
        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div class="relative transform overflow-hidden rounded-lg bg-white dark:bg-[#27272A] px-6 text-left shadow-xl transition-all sm:my-8 w-[620px] h-[450px]">
                    <div class="flex items-center mt-2 mb-4" >
                        <Tabs page="orderinfo" class="m-0" :data="getInfoTabs" @activeTab="activeTabObj" />
                    </div>
                    <div class="flex items-center justify-between mb-2">
                        <div>
                            <button :id="`info_trantype`" :class="$common.getTypeTdClass(infoData.transType)" class="px-3 py-1 text-xs rounded">{{ infoData?.transType == 'B' ? 'BUY' : 'SELL' }}</button>
                            <button id="info_tradsym" class="px-2 text-sm primary-color">{{ infoData?.formattedInsName || infoData?.tradingSymbol }}</button> 
                            <span id="info_exch" class="text-[10px] secondary-color">{{ infoData?.exchange }}</span>
                        </div>
                        <div>
                            <span id="info_orderno" class="secondary-color">Order No : </span><span class="primary-color">{{ infoData?.orderNo }}</span>
                        </div>
                    </div>
                    <div class="border-t border-[#ededed] mb-1"></div>
                    <div v-if="currentTab == 'History'" class="min-h-[240px]">
                    <div class="max-h-[256px] overflow-y-auto">
                        <table id="info_table" class="w-full rounded-b">
                            <thead class="border-b dark:border-gray-500">
                                <tr class="sticky top-0 bg-white dark:bg-zinc-800">
                                    <th class="w-12 py-3.5 px-3 text-left text-xs font-semibold secondary-color" id="info_th_time">Time</th>
                                    <th class="px-3 py-3.5 text-left text-xs font-semibold secondary-color" id="info_th_status">Status</th>
                                    <th class="px-3 py-3.5 text-center text-xs font-semibold secondary-color" id="info_th_exchtime">Exch.time</th>
                                    <!-- <th class="px-3 py-3.5 text-center text-xs font-semibold secondary-color" id="info_th_exchupdate">Exch.update</th> -->
                                    <th class="px-3 py-3.5 text-right text-xs font-semibold secondary-color" id="info_th_qty">Qty</th>
                                    <th class="px-3 py-3.5 text-right text-xs font-semibold secondary-color" id="info_th_filledqty">Filled qty</th>
                                    <th class="px-3 py-3.5 text-right text-xs font-semibold secondary-color" id="info_th_price">price</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white dark:bg-zinc-800 dark:divide-gray-700">
                                <tr v-for="(i, id) in getOrderHistory" :key="id">
                                    <td class="p-2 primary-color text-left text-xs" :id="`info_td_${id}_time`">{{ i?.time?.split(' ')[0] }}</td>
                                    <td class="p-2 primary-color text-left capitalize text-xs" :id="`info_td_${id}_status`">{{ i?.status?.toLowerCase() }}</td>
                                    <td class="p-2 primary-color text-center text-xs" :id="`info_td_${id}_exchtime`">{{ i?.exchTime ? i?.exchTime?.split(' ')[1] : '--'}}</td>
                                    <!-- <td class="p-2 primary-color text-center" :id="`info_td_${id}_exchupdate`">--</td> -->
                                    <td class="p-2 primary-color text-right text-xs" :id="`info_td_${id}_qty`">{{ i?.quantity }}</td>
                                    <td class="p-2 primary-color text-right text-xs" :id="`info_td_${id}_filledqty`">{{ i?.fillshares }}</td>
                                    <td class="p-2 primary-color text-right text-xs" :id="`info_td_${id}_price`">{{ i?.price }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                    <!-- History tab -->
                    <div v-if="currentTab == 'Information'" class="min-h-[240px] overflow-y-auto">
                        <div class="grid grid-flow-col ">
                            <div class="pr-2 border-r border-[#ededed]">
                                <div class="px-2 py-2 flex justify-between items-center">
                                    <div class="secondary-color text-xs" id="info_label_qty">Quantity</div>
                                    <div class="primary-color text-xs" id="info_value_qty">{{ $common.getLotSize(infoData?.lotSize, infoData?.qty, infoData?.exchange) }}</div>
                                </div>
                                <div class="px-2 py-2 flex justify-between items-center">
                                    <div class="secondary-color text-xs" id="info_label_price">Price</div>
                                    <div class="primary-color text-xs" id="info_value_price">{{ infoData?.price }} </div>
                                </div>
                                <div class="px-2 py-2 flex justify-between items-center">
                                    <div class="secondary-color text-xs" id="info_label_trgprc">Trigger Price</div>
                                    <div class="primary-color text-xs" id="info_value_trgprc">{{ infoData?.triggerPrice || '--' }} </div>
                                </div>
                                <div class="px-2 py-2 flex justify-between items-center">
                                    <div class="secondary-color text-xs" id="info_label_ordtype">Order Type</div>
                                    <div class="primary-color text-xs" id="info_value_ordtype">{{ infoData?.priceType }} </div>
                                </div>
                                <div class="px-2 py-2 flex justify-between items-center">
                                    <div class="secondary-color text-xs" id="info_label_product">Product</div>
                                    <div class="primary-color text-xs" id="info_value_product">{{ infoData?.product }} </div>
                                </div>
                            </div>
                            <div class="pl-2">
                                <div class="px-2 py-2 flex justify-between items-center">
                                    <div class="secondary-color text-xs" id="info_label_validity">Validity</div>
                                    <div class="primary-color text-xs" id="info_value_validity">{{ infoData?.ret || 'NA' }} </div>
                                </div>
                                <div class="px-2 py-2 flex justify-between items-center">
                                    <div class="secondary-color text-xs" id="info_label_exch_ordid">Exch. Order ID</div>
                                    <div class="primary-color text-xs" id="info_value_exch_ordid">{{ infoData?.price || 'NA' }} </div>
                                </div>
                                <div class="px-2 py-2 flex justify-between items-center">
                                    <div class="secondary-color text-xs" id="info_label_time">Time</div>
                                    <div class="primary-color text-xs" id="info_value_time">{{ infoData?.orderTime?.split(' ')[0] || 'NA' }} </div>
                                </div>
                                <div class="px-2 py-2 flex justify-between items-center">
                                    <div class="secondary-color text-xs" id="info_label_exch_time">Exch. Time</div>
                                    <div class="primary-color text-xs" id="info_value_exch_time">{{ infoData?.orderTime || 'NA' }} </div>
                                </div>
                                <div class="px-2 py-2 flex justify-between items-center">
                                    <div class="secondary-color text-xs" id="info_label_placedby">Placed by</div>
                                    <div class="primary-color text-xs" id="info_value_placedby">{{ infoData?.userId }} </div>
                                </div>
                            </div>
                        </div>
                        <div class="px-2 mb-4 mt-8" v-if="infoData?.rejectedReason">
                            <div class="secondary-color mb-1" id="info_label_rejreason">Rejected reason: </div>
                            <div class="error text-sm " id="info_value_rejreason"> {{ infoData?.rejectedReason }}</div>
                        </div>
                    </div>

                    <!-- footer -->
                    <div class="flex justify-end my-2">
                        <button id="info_close_btn" @click="$store.commit('setOrderInfoDialog', false)" class="font-medium py-1 rounded h-7 w-[100px] border border-[#a2a2a2] bg-white dark:bg-gray-600 primary-color min-h-[32px]">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            quantity: 0,
            qtyErr: '',
            currentTab: 'Information',
            tabs: [
                { name:'Information', id: 0},
                { name:'History', id: 1 }
            ],
            currentTabId: 0
        }
    },
    props: {
        infoData: { type: Object },
    },
    computed: {
        ...mapGetters('order', ['getOrderHistory','getInfoTabs']),
        ...mapGetters(['getIsOrderInfoDialog']),
    },
    methods: {
        handleTab(data) {
            this.currentTab = data.name
            this.currentTabId = data.id
        },
        activeTabObj(val) {
            val = Number(val)
            this.handleTab(this.tabs[val])
        }
    },
    async mounted() {
        let infoTabs = [
            { name: 'Information', id: 0, active: true, count: '0' },
            { name: 'History', id: 1, active: false, count: '0' },
        ]
        this.$store.commit('order/setInfoTabs', infoTabs)
        this.activeTabObj(0)
    },
}
</script>

<style>
.active-tab {
    @apply !bg-[#F0F6FD] dark:!bg-[#27272A] border border-[#753ED7] text-white
}
.inactive-tab {
    @apply bg-gray-100 border border-gray-100 !text-[#070A26] !font-medium dark:!text-[#ebebeb]
}
</style>
